import React from 'react';

const EventCalendar = ({ events }) => {
    return (
        <div>
            <h3>Event Calendar</h3>
            {/* Kalender-Komponente oder eine einfache Liste */}
            <ul>
                {events.map(event => (
                    <li key={event.id}>
                        {event.name} - {new Date(event.date).toLocaleDateString()}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default EventCalendar;