import React from 'react';

const VenueMap = ({ venue }) => {
    if (!venue) {
        return <p>Loading venue map...</p>;
    }

    return (
        <div>
            <h3>Venue Map</h3>
            <p>Hier wird eine Karte der Event Area angezeigt.</p>
            {/* Hier könnte eine Karte oder Bild des Geländes eingebunden werden */}
            {/*<img src={venue.mapImage} alt="Venue Map" style={{ width: '100%' }} />*/}
        </div>
    );
};

export default VenueMap;