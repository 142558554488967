import React, {useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import './Login.css';

const Login = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    })
    const handleAdminLogin = () => {
        navigate('/admin');
    };

    const handleOrganizerLogin = () => {
        navigate('/organizer');
    }
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const customerData = {
            username: formData.username,
            password: formData.password,
        };
        try {
            const response = await fetch('https://ticket4u.at/api/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(customerData)
            });
            const data = await response.json();
            const token = `${data.tokenType}${data.token}`; //TODO Token für alle weiteren Anfragen mitschicken
            if (response.ok) {
                alert('login erfolgreich!');
                navigate('/customer');
            } else {
                alert('Login fehlgeschlagen!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Ein Fehler ist aufgetreten!');
        }
    };
    return (
        <div className="login-page">
            <header>
                <div className="logoSecondHeader" onClick={() => navigate('/')}>ticket4u</div>
            </header>
            <div className="container">
                <div className="login-box">
                    <h2>Login</h2>
                        <form onSubmit={handleSubmit}>
                            <input type="email" placeholder="Email" name="username" onChange={handleChange} required/>
                            <input type="password" placeholder="Password" name="password" onChange={handleChange} required/>
                            <button type="submit">Login</button>
                        </form>
                        <div className="social-login">
                            <p>Or continue with:</p>
                            <span className="row-container">
                                <button className="google-btn" onClick={handleAdminLogin}>
                                    Google
                                </button>
                                <button className="meta-btn" onClick={handleOrganizerLogin}>Meta</button>
                            </span>
                        </div>
                        <div className="register">
                            <p>Don't have an account? <Link to="/register">Create one</Link></p>
                        </div>
                </div>
            </div>
        </div>
    );
};

export default Login;