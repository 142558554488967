import React from 'react';

const UserList = ({ users }) => {
    return (
        <div>
            <h3>User List</h3>
            <ul>
                {users.map(user => (
                    <li key={user.id}>
                        {user.firstName} {user.lastName} - {user.role}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default UserList;