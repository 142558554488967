import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './Register.css';

const Register = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        address: '',
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const customerData = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            address: formData.address,
            email: formData.email,
            password: formData.password,
        };
        try {
            const response = await fetch('https://ticket4u.at/api/auth/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(customerData)
            });

            if (response.ok) {
                alert('Registrierung erfolgreich!');
                navigate('/login'); // Weiterleitung zum Login nach erfolgreicher Registrierung
            } else {
                alert('Registrierung fehlgeschlagen!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Ein Fehler ist aufgetreten!');
        }
    };

    return (
        <div className="register-page">
            <header>
                <div className="logoRegisterPage" onClick={() => navigate('/')}>ticket4u</div>
            </header>
            <div className="container">
                <div className="register-box">
                    <h2>Registrieren</h2>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="firstName"
                            placeholder="Vorname"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Nachname"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="text"
                            name="address"
                            placeholder="Adresse"
                            value={formData.address}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder="Passwort"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                        <button type="submit">Register</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Register;