import React from 'react';

const ExtraInfo = () => {
    return (
        <div>
            <h3>Extra Information</h3>
            <p>Hier könnte z.B. eine Veranstaltungsstatistik, Aufgabenverwaltung oder andere relevante Informationen erscheinen.</p>
        </div>
    );
};

export default ExtraInfo;