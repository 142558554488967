import React, { useEffect, useState } from 'react';
import './AdminView.css';
import UserList from './UserList';
import EventCalendar from './EventCalendar';
import VenueMap from './VenueMap';
import ExtraInfo from './ExtraInfo';
import {useNavigate} from "react-router-dom";

const AdminView = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [events, setEvents] = useState([]);
    const [venue, setVenue] = useState(null);

    useEffect(() => {
        // API-Call für User
        fetch('http://localhost:8080/Customer')
            .then(response => response.json())
            .then(data => setUsers(data));

        // API-Call für Events
        fetch('http://localhost:8080/Event')
            .then(response => response.json())
            .then(data => setEvents(data));

        // API-Call für das Event-Gelände
        fetch('http://localhost:8080/Event')   // wrong api call to prevent error!!
            .then(response => response.json())
            .then(data => setVenue(data));
    }, []);

    return (
        <div className="organizer-view">
            <header>
                <div className="logoSecondHeader" onClick={() => navigate('/')}>ticket4u</div>
            </header>
            <div className="grid-container">
                <div className="grid-item user-list">
                    <UserList users={users}/>
                </div>
                <div className="grid-item event-calendar">
                    <EventCalendar events={events}/>
                </div>
                <div className="grid-item venue-map">
                    <VenueMap venue={venue}/>
                </div>
                <div className="grid-item extra-info">
                    <ExtraInfo/>
                </div>
            </div>
        </div>
    );
};

export default AdminView;